import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject, debounceTime, fromEvent, takeUntil } from "rxjs";
import { AuthService } from "../../pages/auth/auth.service";

export interface Menu {
  headTitle1?: string;
  level?: number;
  headTitle2?: string;
  path?: string;
  title?: string;
  type?: string;
  icon?: string;
  active?: boolean;
  bookmark?: boolean;
  pinnedData?: boolean;
  items?: Menu[];
  children?: Menu[];
  subtitle?: string;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  private unsubscriber: Subject<any> = new Subject();

  public language: boolean = false;

  public collapseSidebar: boolean = window.innerWidth < 1200 ? true : false;

  constructor(private router: Router, private authService: AuthService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(0), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 1200) {
          this.collapseSidebar = true;
        } else {
          this.collapseSidebar = false;
        }
      });
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  //MENUITEMS: Menu[] = [];
  MENUITEMS: Menu[] = this.returnMenuRole();
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  public returnMenuRole() {
    const expectedRole = 1 || 2;
    const userRole = this.authService.getRole();
    if (userRole === expectedRole) {
      return (this.MENUITEMS = [
        // voci del menu per l'utente Admin o Super Admin
        {
          headTitle1: "Pages",
        },
        {
          level: 1,
          title: "Anagrafiche",
          icon: "anagrafiche",
          type: "sub",
          active: true,
          children: [
            { path: "/anagrafiche/utenti", title: "Utenti", type: "link", bookmark: true },
            { path: "/anagrafiche/strutture", title: "Strutture", type: "link" },
            { path: "/anagrafiche/prodotti", title: "Prodotti", type: "link" },
            { path: "/anagrafiche/attivita", title: "Attività", type: "link" },
            { path: "/anagrafiche/segmentazioni", title: "Suddivisione", subtitle: "Territoriale" , type: "link" },
            { path: "/anagrafiche/medici", title: "Medici", type: "link" },
          ],
        },
        { level: 1, path: "/tipologiche", title: "Tipologiche", icon: "tipologiche", type: "link" },
        {
          level: 1,
          path: "/profilo/visualizza",
          title: "Area Personale",
          icon: "profilo",
          type: "link",
        },
      ]);
    } else {
      return (this.MENUITEMS = [
        { level: 1, path: "/sample-page", title: "Sample Page", icon: "sample-page", type: "link" },
        {
          level: 1,
          path: "/profilo/visualizza",
          title: "Utente",
          icon: "profilo",
          type: "link",
        },
      ]);
    }
    this.items.next(this.MENUITEMS);
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }
}
