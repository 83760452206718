import { Routes } from "@angular/router";
import { SamplePageComponent } from "src/app/pages/simple-page/sample-page/sample-page.component";
import { UserProfileComponent } from "../../pages/user-profile/user-profile.component";
import { GestioneTipologicheComponent } from "../../pages/tipologiche/gestione-tipologiche/gestione-tipologiche.component";
import { RoleGuard } from "../guard/role.guard";

export const content: Routes = [
    {
        path: 'sample-page',
        component: SamplePageComponent,
        data: {
            title: "Sample Page",
            breadcrumb: "Sample Page",
        },
    },
    {
        path: 'pages',
        loadChildren: () => import('../../pages/simple-page/simple-page.module').then(m => m.SimplePageModule),
        data: {
            title: "Sample Page",
            breadcrumb: "Sample Page",
        },
    },
    {
        path: 'profilo',
        children: [
            {
                path: "visualizza",
                component: UserProfileComponent,
                data: {
                    title: "Profilo",
                    breadcrumb: "Profilo",
                },
            },
            {
                path: "modifica",
                component: UserProfileComponent,
                data: {
                    title: "Profilo",
                    breadcrumb: "modifica Profilo",
                },
            }
        ]
    },
    {
        path: "anagrafiche",
        loadChildren: () =>
            import("../../pages/anagrafiche/Utenti/utenti.module").then(m => m.UtentiModule),
        data: {
            title: "Utenti",
            breadcrumb: "Utenti",
        },
        canActivate: [RoleGuard]
    },
    {
        path: "anagrafiche/strutture",
        loadChildren: () =>
            import("../../pages/anagrafiche/strutture/strutture.module").then(m => m.StruttureModule),
        data: {
            title: "Strutture",
            breadcrumb: "Strutture",
        },
        canActivate: [RoleGuard]
    },
    {
        path: "anagrafiche/prodotti",
        loadChildren: () =>
            import("../../pages/anagrafiche/prodotti/prodotti.module").then(m => m.ProdottiModule),
        data: {
            title: "Prodotti",
            breadcrumb: "Prodotti",
        },
        canActivate: [RoleGuard]
    },
    {
        path: "anagrafiche/segmentazioni",
        loadChildren: () =>
            import("../../pages/anagrafiche/segmentazioni-geografiche/segmentazioni-geografiche.module").then(m => m.SegmentazioniGeograficheModule),
        data: {
            title: "Segmentazioni",
            breadcrumb: "Segmentazioni",
        }
    },
    {
        path: 'tipologiche',
        component: GestioneTipologicheComponent,
        data: {
            title: "Tipologiche",
            breadcrumb: "Gestione tipologiche",
        },
        canActivate: [RoleGuard]
    },
    {
        path: "anagrafiche/attivita",
        loadChildren: () =>
            import("../../pages/anagrafiche/Attivita/attivita.module").then(m => m.AttivitaModule),
        data: {
            title: "Attivita",
            breadcrumb: "Attivita",
        },
        canActivate: [RoleGuard]
    },
    {
        path: "anagrafiche/medici",
        loadChildren: () =>
            import("../../pages/anagrafiche/medici/medici.module").then(m => m.MediciModule),
        data: {
            title: "Medici",
            breadcrumb: "Medici",
        },
        canActivate: [RoleGuard]
    },


]


