import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { content } from './shared/routes/routes';
import { AuthGuard } from './shared/guard/auth.guard';
import { environment as env } from 'src/environments/environment';

export const routes: Routes = [
  {
    path: '',
    redirectTo: env.home,
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((x) => x.AuthModule),
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
