import { Location } from "@angular/common";
import { Component, ErrorHandler, OnDestroy, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2';
import { CommonServiceService } from "../../services/common-service.service";

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrl: './base-component.component.scss'
})
export class BaseComponent implements OnInit, OnDestroy {
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected errorHandler: ErrorHandler = inject(ErrorHandler);
  protected router: Router = inject(Router);
  protected location: Location = inject(Location);
  protected commonService: CommonServiceService = inject(CommonServiceService);
  protected Swal = Swal;

  ngOnInit() { }

  ngOnDestroy() { }

}

export class BaseComponentHeader extends BaseComponent {
  constructor() {
    super();
  }

}
export class BaseComponentFooter extends BaseComponent {
  constructor() {
    super();
  }

}
